<template>
    <div class="row">
        <div class="question-title">
<!--            <span>2021-09-02</span>-->
            <span>{{getItem('exam_name')}}</span>
            <span>{{getItem('className')}}</span>
        </div>
        <div class="modular-list" v-if="$i18n.locale=='zh'">
            <table>
                <tr v-for="(item,index) in questionList" :key="index">

                    <td> <span :class="item.active?'active':''" @click="questionActive(index)"> <i></i> </span> </td>
                    <td>

                        <p style="margin: 0" class="box-card-text" v-html="item.question_title_cn"></p>
                    </td>
                    <td>
<!--                        <img :src="$store.state.imgHttp+item.question_sub_cn" alt="">-->
                        <p class="content-p" v-html="item.question_sub_cn"></p>
                    </td>
                    <td> <a @click="seeYuanTi(item)">{{$t('home.CheckTheOriginalQuestion')}}</a> </td>
                </tr>
            </table>
        </div>
<div class="modular-list" v-if="$i18n.locale=='en'">
            <table>
                <tr v-for="(item,index) in questionList" :key="index">

                    <td> <span :class="item.active?'active':''" @click="questionActive(index)"> <i></i> </span> </td>
                    <td>
                        <p style="margin: 0" class="box-card-text" v-html="item.question_title_en"></p>
                    </td>
                    <td>
<!--                        <img :src="$store.state.imgHttp+item.question_sub_cn" alt="">-->
                        <p class="content-p" v-html="item.question_sub_en"></p>
                    </td>
                    <td> <a @click="seeYuanTi(item)">{{$t('home.CheckTheOriginalQuestion')}}</a> </td>
                </tr>
            </table>
        </div>
        <q-dialog v-model="yuanti" content-class="pop" persistent
      :maximized="true"
      transition-show="slide-up"
      transition-hide="slide-down">
<!-- style="width: 1180px; max-width: 80vw;" -->
            <div class="pop-box" >
                <q-btn class="pop-close" @click="yuanti = false" style="color: #FF0000;z-index:9999" icon="close" flat round dense  />
                <q-card-section style="max-height: 100vh" class="scroll">
                    <OriginalTitle :qusData="qusData" :qusType="qusType"></OriginalTitle>
                </q-card-section>
            </div>
        </q-dialog>
        <div class="modular-botton">
            <div class="modular-botton-left">
                {{$t('home.YouHaveBeenSelected')}} {{activeNum}} {{$t('home.Questions')}}
            </div>
            <div class="modular-botton-right">
                <q-btn class="modular-botton-right-btn" unelevated  flat :label="$t('home.determine')" @click="determine()"  />
            </div>
        </div>
    </div>
</template>

<script>
    import OriginalTitle from '../components/OriginalTitle'
    export default {
        name: "QuestionBank",
        components:{
            OriginalTitle
        },
        data(){
            return{
                questionList:[],
                activeNums:0,
                yuanti:false,
                qusData:[],
                qusType:null,
            }
        },
        created() {
            this.getTiXingList(this.$route.query.type,this.$route.query.id)
            console.log(this.$store.state.xuanzeTiMuListZhi,'选择的数组')
        },
        watch:{
            "$store.state.topQueDing"() {
                console.log(this.$store.state.topQueDing)
                if (this.$store.state.topQueDing == '/questionBank'){
                    this.determine()
                }
            }
        },
        methods:{
            // 查看原题
            seeYuanTi(item){
                console.log('查看原题',item)
                this.yuanti = true
                this.qusData = item
                this.qusType = item.question_type_id
            },
            questionActive(index){
                this.questionList[index].active = !this.questionList[index].active
            },
            // 获取题型列表 exam/classify_knowledge_zt
            getTiXingList(type,id){
                this.$http.post('exam/classify_knowledge_zt',{
                    type:type,
                    id:id
                }).then(res =>{
                    console.log(res)
                    res.data.forEach(item =>{
                        item.active = false
                    })
                    this.questionList = res.data
                    this.setListData()
                })
            },
            // 确定选择
            determine(){
                // 判断题目数量
                if (this.$route.query.type == 'knowledge'){
                    this.questionList.forEach(item =>{
                        if (item.active){
                            if (this.$store.state.xuanzeTiMuListZhi.includes(item.id)){
                                console.log('已存在')
                            }else {
                                this.$store.state.xuanzeTiMuListZhi.push(item.id)
                            }
                            this.activeNums++
                        }else {
                            var a = this.$store.state.xuanzeTiMuListZhi.findIndex(items => items == item.id);
                            if (a != -1){
                                this.$store.state.xuanzeTiMuListZhi.splice(a, 1);
                            }
                        }
                    })
                    this.$store.state.zhishiDianList[sessionStorage.getItem('activeIndex')].activeNums = this.activeNums
                }else {
                    this.questionList.forEach(item =>{
                        if (item.active){
                            if (this.$store.state.xuanzeTiMuList.includes(item.id)){
                                console.log('已存在')
                            }else {
                                this.$store.state.xuanzeTiMuList.push(item.id)
                            }
                            this.activeNums++
                        }else {
                            var a = this.$store.state.xuanzeTiMuList.findIndex(items => items == item.id);
                            if (a != -1){
                                this.$store.state.xuanzeTiMuList.splice(a, 1);
                            }
                        }
                    })
                    this.$store.state.teacherClassList[sessionStorage.getItem('activeIndex')].activeNums = this.activeNums
                }
                this.$router.go(-1)
            },
            // 已选择的列表赋值
            setListData(){
                if (this.$route.query.type == 'knowledge'){
                    this.$store.state.xuanzeTiMuListZhi.forEach((items)=>{
                        this.questionList.forEach((item,index)=>{
                            if (item.id == items){
                                console.log('选中',item)
                                console.log('选中',items)
                                this.questionList[index].active = true
                            }
                        })
                    })

                }else {
                    console.log('分类',this.$store.state.xuanzeTiMuList)
                    this.$store.state.xuanzeTiMuList.forEach((items)=>{
                        this.questionList.forEach((item,index)=>{
                            if (item.id == items){
                                console.log('选中',item)
                                console.log('选中',items)
                                this.questionList[index].active = true
                            }
                        })
                    })
                }
            }
        },
        computed:{
            activeNum(){
                let num = 0
                this.questionList.forEach((item)=>{
                    if (item.active){
                        num++
                    }
                })
                return num
            }
        }
    }
</script>

<style scoped lang="less">
.box-card-text{
    font-size: 16px !important;
}
    .question-title{
        display: flex;
        align-items: center;
        padding: 0 37px;
        margin-top: 27px;
        span{
            display: inline-block;
            font-size: 20px;
            color: #ED711C;
            margin-right: 30px;
        }
    }
    .modular-list{
        width: 100%;
        height: 419px;
        overflow: auto;
        padding: 0 33px;
        padding-top: 20px;
        table{
            width: 100%;
            tr{
                width: 100%;
                /*min-height: 50px;*/
                background: #F4F4F4;
                border-radius: 20px;
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 31px;
                p{
                    margin-bottom: 0;
                }
                td{
                    font-size: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    span{
                        width: 22px;
                        height: 22px;
                        border: 1px solid #626262;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &:hover{
                            border: 1px solid #ED711C;
                        }
                        i{
                            display: inline-block;
                            width: 90%;
                            height: 90%;
                            background: #ED711C;
                            transition: all 0.3s;
                            opacity: 0;
                        }
                        transition: all 0.3s;
                    }
                    span.active{
                        border: 1px solid #ED711C;
                    }
                    span.active i{
                        display: inline-block;
                        opacity: 1;
                    }
                    a{
                        color: #ED711C;
                    }
                    .des{
                        font-size: 14px;
                        color: #666666;
                    }
                    &:nth-child(2){
                        width: 305px;
                        height: 50px;
                        overflow: hidden;
                        img{
                            object-fit: none;
                            width: 100%;
                            height: 100%;
                        }
                    }
                    &:nth-child(3){
                        width: 305px;
                        overflow: hidden;
                        .content-p{
                            max-height: 50px;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
    }
    .modular-botton{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 69px;
        padding-top: 20px;
        border-top: 2px solid #ED711C;
        .modular-botton-left{
            font-size: 18px;
            color: #ED711C;
            margin-left: 59px;
        }
        .modular-botton-right-btn{
            // width: 86px;
            height: 34px;
            background: #ED711C;
            color: #ffffff;
            border-radius: 13px;
            margin-right: 70px;
            font-size: 18px;
            /deep/ .q-btn__wrapper{
                min-height: auto;
            }
        }
    }

</style>