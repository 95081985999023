<template>
    <div>
        <q-card class="my-card" flat id="q-content">
            <!--选择题-->
            <ChoiceQuestion v-if="qusType === 1" :qusData="qusData"></ChoiceQuestion>
            <!--多择题 || 判断题 -->
            <MultipleChoice v-if="qusType === 2 || qusType === 3" :qusData="qusData"></MultipleChoice>
            <!--填空题-->
            <supplementQuestion v-if="qusType === 5" :qusData="qusData"></supplementQuestion>
            <!--拖拽题-->
            <DragQuestion v-if="qusType === 4" :qusData="qusData"></DragQuestion>
        </q-card>
    </div>
</template>

<script>
    import ChoiceQuestion from "../components/wrongQuestion/ChoiceQuestion";
    import MultipleChoice from "@/components/wrongQuestion/MultipleChoice"
    import DragQuestion from "@/components/wrongQuestion/DragQuestion"
    import supplementQuestion from "@/components/wrongQuestion/supplementQuestion"
    export default {
        name: "OriginalTitle",
        props:['qusData','qusType'],
        components:{
            ChoiceQuestion,
            MultipleChoice,
            DragQuestion,
            supplementQuestion
        },
        data() {
            return{
                current: 1,
                qusTab: true,
                max:0,
                pageList:[],
            }
        }
    }
</script>

<style scoped>

</style>